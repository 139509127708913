import './AddServiceFigures.css'

export default function AddServiceFigures() {
    return (
        <div>
            <h2>Add Service Figures</h2>
        </div>
    )
}

