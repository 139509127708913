// styles
import { useState, } from 'react';
import { useLogin } from '../../hooks/useLogin';
import './Login.css'

export default function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { login, isPending, error } = useLogin();

    const handleSubmit = (e) =>{
        e.preventDefault()
        login(email, password);
    }

    return (
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Log in</h2>
        <label>
          <span>email:</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <span>password:</span>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <span className='forgot-pwd'>Forgot Password?</span>
        </label>
        
        {!isPending && <button className="btn">Log in</button>}
        {isPending && (<button disabled className="btn">Loading...</button>)}
        {error && <div className="error">{error}</div>}
      </form>
    );
}
